@font-face {
  font-family: 'Corporate-A Regular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(./assets/fonts/CorporateA-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Corporate-A Regular';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(./assets/fonts/CorporateS-Demi.woff2) format('woff2');
}

@font-face {
  font-family: 'Corporate-A Regular';
  font-style: normal;
  font-weight: lighter;
  font-display: swap;
  src: url(./assets/fonts/CorporateS-Light.woff2) format('woff2');
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  background-color: black;
  color: white;
  margin: 0;
  font-family: 'CorporateA Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  visibility: hidden;
}

button {
  outline: none;
}

.h2-5 {
  height: 3rem;
}

.f6-5 {
  font-size: 0.75rem;
}

.f8 {
  font-size: 0.6rem;
}

.w-960px {
  min-width: 960px;
}

.w4-5 {
  width: 9.6rem;
}

.no-select {
  user-select: none;
}

.top-x {
  top: 8rem;
}

.bottom-x {
  bottom: 2rem;
}

.h-100-vh {
  min-height: 100vh;
  max-height: 100vh;
}

.max-h-100 {
  max-height: 100%;
}

.fade-in {
	animation: fadeIn ease 1s;
	-webkit-animation: fadeIn ease 1s;
	-moz-animation: fadeIn ease 1s;
	-o-animation: fadeIn ease 1s;
	-ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
	0% {opacity:0;}
	35% {opacity:1;}
	65% {opacity:1;}
	100% {opacity:0;}
}
